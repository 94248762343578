var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c("header", { staticClass: "app-welcome-message" }, [
        !_setup.isAddingMasterMode && !_setup.isAddingDetailMode
          ? _c("h2", [_vm._v("Details van installatiegegevens")])
          : _vm._e(),
        _setup.isAddingMasterMode
          ? _c("h2", [_vm._v("Toevoegen van installatiegegevens")])
          : _vm._e(),
        _setup.isAddingDetailMode
          ? _c("h2", [_vm._v("Toevoegen van installatiegegevensversie")])
          : _vm._e(),
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _setup.hasDetails
                        ? _c("v-col", { attrs: { cols: "auto" } }, [
                            _vm._v(" Tijdlijn: "),
                          ])
                        : _vm._e(),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn-toggle",
                            {
                              attrs: {
                                group: "",
                                mandatory: "",
                                color: "blue accent-3",
                              },
                              model: {
                                value: _setup.selectedTabIndex,
                                callback: function ($$v) {
                                  _setup.selectedTabIndex = $$v
                                },
                                expression: "selectedTabIndex",
                              },
                            },
                            _vm._l(_setup.detailTabs(), function (item, index) {
                              return _c(
                                "v-btn",
                                {
                                  key: index,
                                  staticClass: "v-application elevation-2",
                                  attrs: {
                                    value: index,
                                    disabled: !_setup.isReadOnly,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                _setup.ValidationObserver,
                { ref: "obs" },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "masonry",
                        {
                          attrs: { cols: { default: 2, 1264: 1 }, gutter: 12 },
                        },
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: {
                                noBorders:
                                  !_setup.isEditTLMode &&
                                  !_setup.isAddingMasterMode &&
                                  !_setup.isAddingDetailMode,
                              },
                              attrs: { fluid: "" },
                            },
                            [
                              _c("header", [_vm._v("Geldigheid")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Geldig vanaf",
                                          rules: "date_between|required",
                                          mode: _setup.checkDateValid,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Geldig vanaf",
                                                    errors: errors,
                                                    outlined:
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEditTL,
                                                    rules: "required",
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .valFrom,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "valFrom",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.valFrom",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Geldig tot",
                                          rules: `date_between|date_after:${_setup.selectedData.valFrom}`,
                                          mode: _setup.checkDateValid,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Geldig tot",
                                                    errors: errors,
                                                    outlined:
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEditTL,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .valTill,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "valTill",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.valTill",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: {
                                noBorders:
                                  !_setup.isAddingMasterMode &&
                                  !_setup.isAddingDetailMode,
                              },
                              attrs: { fluid: "" },
                            },
                            [
                              _c("header", [_vm._v("Aanpassing")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Aanpassings omschrijving",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit &&
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Omschrijving",
                                                    "error-messages": errors,
                                                    outlined:
                                                      _setup.allowEdit ||
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEdit &&
                                                      !_setup.allowEditTL,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .modDescr,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "modDescr",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.modDescr",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_setup.isAddingMasterMode &&
                              !_setup.isAddingDetailMode
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Door",
                                              "persistent-placeholder": "",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _setup.selectedData.modBy,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.selectedData,
                                                  "modBy",
                                                  $$v
                                                )
                                              },
                                              expression: "selectedData.modBy",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_setup.isAddingMasterMode &&
                              !_setup.isAddingDetailMode
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Datum",
                                              "persistent-placeholder": "",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _setup.modDateFormatted,
                                              callback: function ($$v) {
                                                _setup.modDateFormatted = $$v
                                              },
                                              expression: "modDateFormatted",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: { noBorders: !_setup.isAddingMasterMode },
                              attrs: { fluid: "" },
                            },
                            [
                              _c("header", [_vm._v("Installatie definitie")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Type",
                                          rules: "unequal_Onbekend",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.isAddingMasterMode,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Type",
                                                    "persistent-placeholder":
                                                      "",
                                                    items: _setup.types,
                                                    "item-text": "description",
                                                    "item-value": "key",
                                                    "error-messages": errors,
                                                    outlined:
                                                      _setup.isAddingMasterMode,
                                                    readonly:
                                                      !_setup.isAddingMasterMode,
                                                  },
                                                  model: {
                                                    value: _setup.selectedType,
                                                    callback: function ($$v) {
                                                      _setup.selectedType = $$v
                                                    },
                                                    expression: "selectedType",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Referentie",
                                          rules: "required|max:40",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.isAddingMasterMode,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Referentie",
                                                    "persistent-placeholder":
                                                      "",
                                                    counter: 40,
                                                    "error-messages": errors,
                                                    success: valid,
                                                    outlined:
                                                      _setup.isAddingMasterMode,
                                                    readonly:
                                                      !_setup.isAddingMasterMode,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData.ref,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "ref",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.ref",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Groep",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.isAddingMasterMode,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Groep",
                                                    "persistent-placeholder":
                                                      "",
                                                    items: _setup.groups,
                                                    "item-text": "ref",
                                                    "item-value": "id",
                                                    "error-messages": errors,
                                                    outlined:
                                                      _setup.isAddingMasterMode,
                                                    readonly:
                                                      !_setup.isAddingMasterMode,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .groupId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "groupId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.groupId",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "masonry",
                        {
                          attrs: {
                            cols: { default: 3, 1264: 2, 768: 1 },
                            gutter: 12,
                          },
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "boxed", attrs: { fluid: "" } },
                            [
                              _c("header", [_vm._v("Kenmerken")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Constructiedatum",
                                          mode: _setup.checkDateValid,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    value:
                                                      _setup.selectedData
                                                        .constrDate,
                                                    label: "Constructiedatum",
                                                    "persistent-placeholder":
                                                      "",
                                                    errors: errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                    rules: "required",
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.constrDate =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Merk",
                                          rules: "max:100",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Merk",
                                                    "persistent-placeholder":
                                                      "",
                                                    counter: 100,
                                                    "error-messages": errors,
                                                    success: valid,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData.brand,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "brand",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.brand",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Model",
                                          rules: "max:50",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Model",
                                                    "persistent-placeholder":
                                                      "",
                                                    counter: 50,
                                                    "error-messages": errors,
                                                    success: valid,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData.model,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "model",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.model",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          label: "ProductType",
                                          "persistent-placeholder": "",
                                          items: _setup.productTypes,
                                          "item-text": "description",
                                          "item-value": "id",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                          placeholder: _setup.placeholderType,
                                        },
                                        model: {
                                          value:
                                            _setup.selectedData.productTypeId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "productTypeId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedData.productTypeId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          label: "ControlType",
                                          "persistent-placeholder": "",
                                          items: _setup.controlTypes,
                                          "item-text": "name",
                                          "item-value": "id",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                          placeholder: _setup.placeholderType,
                                        },
                                        model: {
                                          value:
                                            _setup.selectedData.controlTypeId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "controlTypeId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedData.controlTypeId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          label: "Detail type",
                                          "persistent-placeholder": "",
                                          items: _setup.detailTypes,
                                          "item-text": "description",
                                          "item-value": "id",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                          placeholder: _setup.placeholderType,
                                        },
                                        model: {
                                          value:
                                            _setup.selectedData.detailTypeId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "detailTypeId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedData.detailTypeId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          label: "Sub-Detail type",
                                          "persistent-placeholder": "",
                                          items: _setup.subDetailTypes,
                                          "item-text": "description",
                                          "item-value": "id",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                          placeholder: _setup.placeholderType,
                                        },
                                        model: {
                                          value:
                                            _setup.selectedData.subDetailTypeId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "subDetailTypeId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedData.subDetailTypeId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          label: "Samenwerkings type",
                                          "persistent-placeholder": "",
                                          items: _setup.collaborationTypes,
                                          "item-text": "description",
                                          "item-value": "id",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                          placeholder: _setup.placeholderType,
                                        },
                                        model: {
                                          value:
                                            _setup.selectedData
                                              .collaborationTypeId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "collaborationTypeId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedData.collaborationTypeId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          label: "Afgifte systeem",
                                          "persistent-placeholder": "",
                                          items: _setup.deliveryTypes,
                                          "item-text": "description",
                                          "item-value": "id",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                          placeholder: _setup.placeholderType,
                                        },
                                        model: {
                                          value:
                                            _setup.selectedData.deliveryTypeId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "deliveryTypeId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedData.deliveryTypeId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          label: "Aanvoerbron",
                                          "persistent-placeholder": "",
                                          items: _setup.inputSources,
                                          "item-text": "description",
                                          "item-value": "id",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                          placeholder: _setup.placeholderType,
                                        },
                                        model: {
                                          value:
                                            _setup.selectedData.inputSourceId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "inputSourceId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedData.inputSourceId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "InputValue",
                                          rules: "max:50",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Aanvoerwaarde",
                                                    "persistent-placeholder":
                                                      "",
                                                    counter: 50,
                                                    "error-messages": errors,
                                                    success: valid,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .inputValue,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "inputValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.inputValue",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Surface",
                                          rules:
                                            "decimal:2|between:0,9999999.99|min_value:1",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    "hide-details": "auto",
                                                    label: "Oppervlakte",
                                                    "persistent-placeholder":
                                                      "",
                                                    value:
                                                      _setup.selectedData
                                                        .surface,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.surface =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Aantal",
                                          rules:
                                            "decimal:2|between:0,9999999.99|min_value:1",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    "hide-details": "auto",
                                                    label: "Aantal",
                                                    "persistent-placeholder":
                                                      "",
                                                    value:
                                                      _setup.selectedData.qty,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.qty =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed", attrs: { fluid: "" } },
                            [
                              _c("header", [_vm._v("Verbruiken")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Elektra",
                                          rules:
                                            "decimal:4|between:0,99999999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "auto",
                                                      "persistent-placeholder":
                                                        "",
                                                      label: "Elektra",
                                                      value:
                                                        _setup.selectedData
                                                          .consElk,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.consElk =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("kWh/jaar")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Gas",
                                          rules:
                                            "decimal:4|between:0,99999999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "auto",
                                                      label: "Gas",
                                                      "persistent-placeholder":
                                                        "",
                                                      value:
                                                        _setup.selectedData
                                                          .consGas,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.consGas =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("Nm³/jaar")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Warmte",
                                          rules:
                                            "decimal:4|between:0,99999999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "auto",
                                                      label: "Warmte",
                                                      "persistent-placeholder":
                                                        "",
                                                      value:
                                                        _setup.selectedData
                                                          .consHeat,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.consHeat =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("GJ/jaar")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Koude",
                                          rules:
                                            "decimal:4|between:0,99999999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "auto",
                                                      label: "Koude",
                                                      "persistent-placeholder":
                                                        "",
                                                      value:
                                                        _setup.selectedData
                                                          .consCold,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.consCold =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("GJ/jaar")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "consOther",
                                          rules:
                                            "decimal:4|between:0,99999999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    "hide-details": "auto",
                                                    label: "Anders",
                                                    "persistent-placeholder":
                                                      "",
                                                    value:
                                                      _setup.selectedData
                                                        .consOther,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.consOther =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed", attrs: { fluid: "" } },
                            [
                              _c("header", [_vm._v("Output")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Piek",
                                          rules:
                                            "decimal:4|between:0,99999999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    "hide-details": "auto",
                                                    label: "Piek",
                                                    "persistent-placeholder":
                                                      "",
                                                    value:
                                                      _setup.selectedData
                                                        .outPeak,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.outPeak =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Gemiddeld",
                                          rules:
                                            "decimal:4|between:0,99999999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    type: "number",
                                                    "hide-details": "auto",
                                                    label: "Gemiddeld",
                                                    "persistent-placeholder":
                                                      "",
                                                    value:
                                                      _setup.selectedData
                                                        .outAvg,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.outAvg =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div"),
                          _c(
                            "v-container",
                            { staticClass: "boxed", attrs: { fluid: "" } },
                            [
                              _c("header", [_vm._v("Ligging PV installatie")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Azimut",
                                          rules: "decimal:4|between:0,360",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "auto",
                                                      label: "Azimut",
                                                      "persistent-placeholder":
                                                        "",
                                                      value:
                                                        _setup.selectedData
                                                          .azimut,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.azimut =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("graden")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Helling",
                                          rules: "decimal:4|between:-90,90",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "auto",
                                                      label: "Helling",
                                                      "persistent-placeholder":
                                                        "",
                                                      value:
                                                        _setup.selectedData
                                                          .incl,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.incl =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("graden")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("details-buttons", {
                        attrs: {
                          addMode: _setup.isAddingMode,
                          editMode: !_setup.isReadOnly,
                          allowSave: !_setup.isSaving,
                        },
                        on: {
                          edit: _setup.setEditMode,
                          "edit-tl": _setup.setEditTLMode,
                          "on-cancel": _setup.onCancel,
                          "add-new-detail": _setup.addNewDetail,
                          "on-cancel-add": _setup.onCancelAdd,
                          "save-detail": _setup.saveDetail,
                          "save-new-detail": _setup.saveNewDetail,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }